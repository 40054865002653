<template>
  <div>
    <ManageLeave />
  </div>
</template>
<script>
import ManageLeave from "@/components/Leave/ManageLeave";
export default {
  components: {
    ManageLeave,
  },
  created() {},
};
</script>
c
